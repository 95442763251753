import React from "react";

const AboutUs = () => {
    return(
      <div className="container">
        <div className="wrapper">
          <p className="big">
          TransMe is the best dating App for transgender women & men, and their admirers who are looking for friendship and serious relationship.
          <br />
          <br />
          We hope to build a high-quality transgender dating App. We are try out best to monitor of our member profiles and activities. To avoid fake profiles or scammers, we preview all profiles, photos and moments manually, so it is totally free and safe to place a profile here
          <br />
          <br />
          TransMe App is a special dating app for MTF, FTM and transgender people. It is the best transgender community to meet male-to-female, female-to-male, pre-op male-to-female, post-op male-to-female, pre-op female-to-male, or post-op female-to-male, androgynous, intersexed male and intersexed female.
          <br />
          <br />
          No matter you are a transgender woman looking for a genetics or transgender man, or a transgender man looking for a intersex or transgender woman, TransMe app is the right transgender community where can find and hook up with a FTM or MTF.
          <br />
          <br />
          TransMe is dedicated to all kinds of transgender individuals such as trans women & men, ladyboys, cross-dressers, panty boys, sissy boys and so on. As a trans woman, it may be difficult to find sincere local partner for serious relationship. With the larggest transgender app TransMe, you are able to lucky meet thousands of local transgender people and their admirers.
        </p>
        </div>
      </div>
    )
  }

export default AboutUs;