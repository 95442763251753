import React from "react";

const Terms = () => {
    return (
      <div className="container">
        <div className="wrapper">
          <h2>TransMe Terms of Use</h2>
          <p>Last revised on Jul 31, {new Date().getFullYear()}</p> <br/>
          <p>Welcome to TransMe. By using myeasydating.com, TransMe mobile, applications or services offered by TransMe. as part of the TransMe enterprise (the “Services") you agree to all the terms below.if you do not understand or do not agree to these terms,do not use the service.use of the service is void where prohibited.</p>
          <h2>Privacy </h2><br />
          <p> Your privacy is important to us. Please carefully review our privacy policy at&nbsp;<a href="https://myeasydating.com/pp">myeasydating.com/pp</a>. Our privacy policy details the way we collect, use and disclose information about you.</p>
          <h2>Eligibility to Use the Services </h2><br />
          <p>You must be 18 years or older to access or make use of the Services.You must have a valid Facebook account if using the Facebook platform sign-up for an account with the Services. Or you must have a valid email address if using an email platform to sign up for an account. If you create an account, you represent and warrant that you have never been convicted of a felony and that you are not required to register as a sex offender with any government entity. Using the Service may be prohibited or restricted in certain countries. If you use the Service from outside of the United States, you are responsible for complying with the laws and regulations of the territory from which you access or use the Service.</p>
          <p>You are responsible for maintaining the security of your account and ensuring that no unauthorized person has access to your account. In the event an unauthorized person gains access to your account, you must notify TransMe immediately at&nbsp;<a href="mailto:mytransdating@gmail.com">mytransdating@gmail.com</a>. If you have previously been suspended or removed from the Services than you can not access or use the Services.</p>
          <h2>Changes to Terms or Services </h2>
          <p>We may modify the Terms at any time, in our sole discretion. If we do so, we'll let you know either by posting the modified Terms on the Site or through other communications. It's important that you review the Terms whenever we modify them because if you continue to use the Services after we have posted modified Terms on the Site, you are agreeing to be bound by the modified Terms. If you object to any change or modification, your sole recourse shall be to cease using the Services. YOU AGREE THAT TransMe SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY CHANGE OR MODIFICATION TO THESE TERMS. Except as may be expressly specified otherwise by TransMe with respect to paid portions of the Services, we reserve the right to add, change, suspend or discontinue the Services, or any aspect or feature of the Services, without notice or liability.</p>
          <h2>CONTENTS </h2><br />
          <h2>Content and Content Rights </h2><br />
          <p>For purposes of these Terms: "Content" means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services; and "User Content" means any Content that Account holders (including you) provide to be made available through the Services. Accordingly, Content includes but is not limited to User Content.</p>
          <h2>Content Ownership, Responsibility and Removal </h2><br />
          <p>TransMe does not claim any ownership rights in any Content that you make available through the Services and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your User Content. Subject to the foregoing, TransMe and its licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of any countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.</p>
          <h2>Rights in User Content Granted by You </h2><br />
          <p>By making any User Content available through Services you hereby grant to TransMe a perpetual, non-exclusive, transferable, worldwide, royalty-free license to use, copy, modify, create derivative works based upon, distribute, publicly display, publicly perform and distribute your User Content in connection with operating and providing the Services and Content to you and to other Account holders.</p>
          <p>You are solely responsible for all your User Content. You represent and warrant that you own all your User Content or you have all rights that are necessary to grant us the license rights in your User Content under these Terms. You also represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by TransMe on or through the Services will infringe, misappropriate or violate a third party's intellectual property rights, rights of publicity or privacy, or any other rights or result in the violation of any applicable law or regulation.<br />
            You can remove your User Content by specifically deleting it from your profile. However, in certain instances, some of your User Content (such as virtual gifts you give or chat messages you send) may not be completely removed and copies of your User Content may continue to exist on the Services. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.</p>
          <h2>Rights in Content Granted by TransMe </h2><br />
          <p>Subject to your compliance with these Terms, TransMe grants you a limited, non-exclusive, non-transferable, non-sublicenseable license to download, view, copy, display and print the Content solely in connection with your permitted use of the Services and solely for your personal and non-commercial purposes.<br />
            Each user is responsible for any content or information they post or transmit in the Services and TransMe assumes no responsibility for the conduct of any user submitting any content or for the information transmitted by any user. You shall not make available in the Services any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person.</p>
          <p>TransMe assumes no responsibility for monitoring the Services for inappropriate or illegal content or conduct, and has no obligation to monitor player content. To the extent TransMe becomes aware of any player content it shall have the right, in its sole discretion, to edit, refuse to post, or remove any player content. As you use the Services, TransMe may monitor and/or record your use of the Services and/or communications while you are playing the Services and you hereby provide your irrevocable consent to such monitoring and recording.</p>
          <h2>USE OF SERVICES </h2><br />
          <p>Your use of the Services shall be for non-commercial purposes, which means you shall not gather Virtual Currency or Virtual Goods for sale or trade nor shall you sublicense, rent, lease, sell, trade, gift, bequeath or otherwise transfer your Account or any Virtual Currency or Virtual Goods associated with your Account to anyone without TransMe's written permission. You hereby represent and warrant that you have not and will not access or use an Account, Virtual Currency or Virtual Goods that have been sublicensed, rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the original Account creator without TransMe's consent.<br />
            In addition, you shall not:</p>
          <p>1. Attempt to use the Services to stalk, harass, abuse, or harm, or advocate or incite stalking, harassment, abuse or harm of another person or group.</p>
          <p>2. Post, publicly and privately any abusive, threatening, obscene, defamatory, or racially, sexually, religiously, or otherwise objectionable or offensive content or information or any content or information that contains nudity, excessive violence, or offensive subject matter or that contains a link to such content.</p>
          <p>3. Inappropriately solicit or attempt to solicit personal information from other users of the Services, nor shall you harvest or post anyone's private information, including personally identifiable or financial information in or through the use of the Services.</p>
          <p>4. Advertise, spam or distribute any malware, spyware or other malicious content in or through the Services</p>
          <p>5. Engage in any illegal conduct or intentionally or unintentionally violate, or promote the violation of, any applicable laws or regulations.</p>
          <p>6. Disrupt, overburden, of any computer or server used by or in the Services or become involved in any type of attack (including but not limited to distribution of a virus, denial of service attacks) or in any other way attempt to disrupt the Services or any other player's use or enjoyment of the Services.</p>
          <p>7. Cheat or disrupt the Services in any manner (including but not limited to using cheats, exploits, automation software, bots, hacks, mods or any software designed to modify or interfere with the Services or overall experience). Cheating includes, without limitation, any automated use of the Services or circumventing or modifying any security, technology or device that is part of the Services.</p>
          <p>8. Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or other intellectual property used in the Services.</p>
          <p>9. Attempt to gain unauthorized access to the Services, player accounts registered to others or to the computers, servers, or networks connected to the Services by any means other than the Services' user interface.</p>
          <p>This provision is not meant to be exhaustive, and TransMe reserves the right to determine what conduct it considers to be in violation of this provision or otherwise outside the spirit of the Services and to take action, which may include termination of your Account and exclusion from further participation in the Services.</p>
          <h2>Disclosure to Protect Abuse Victims </h2><br />
          <p>Notwithstanding any other provision of these Terms, TransMe reserves the right, but has no obligation, to disclose any information that you submit to the Service if in its sole opinion TransMe suspects or has reason to suspect that the information involves a party who may be the victim of abuse in any form. Abuse may include without limitation elder abuse, child abuse, spousal abuse, neglect, or domestic violence. Information may be disclosed to authorities that TransMe in its sole discretion, deems appropriate to handle such disclosure. Appropriate authorities may include without limitation law enforcement agencies, child protection agencies, or court officials. You hereby acknowledge and agree that TransMe is permitted to make such disclosures.</p>
          <h2>TransMe's Enforcement Rights </h2><br />
          <p>Although we are not obligated to monitor access to or use of the Services or Content or to review or edit any Content, we have the right to do so including, but not limited to, for the purpose of operating the Services, to ensure compliance with these Terms, or to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, in our sole discretion, consider any Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</p>
          <h2>In App Purchases </h2><br />
          <p>From time to time, TransMe may offer additional products and services for purchase through the App Store , Google Play or other application platforms ("in app purchases"). If you choose to make an in app purchase, you will be prompted to enter details for your account with the mobile platform you are using, and your account will be charged for the in app purchase in accordance with the terms disclosed to you at the time of purchase as well as the general terms for in app purchases that apply to your IAP Account. If you purchase an auto-recurring periodic subscription through an in app purchase, your IAP Account will be billed continuously for the subscription until you cancel in accordance with the platform terms. In call cases, please refer to the terms of your application platform which apply to your in app purchases.</p>
          <h2>Player Submissions </h2><br />
          <p>The Services may provide you and other users the opportunity to submit questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials or other information about the Services (the "Submissions"). Submissions, however they are transmitted, are non-confidential as between you and TransMe and shall become the sole property of TransMe upon receipt. TransMe shall own, and you hereby assign to TransMe, all right title and interest, including all intellectual property rights, in and to such Submissions, and TransMe shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You agree to execute any documentation required by TransMe (in its sole discretion) to confirm such assignment to, and unrestricted use and dissemination by, TransMe of such Submissions.</p>
          <h2>Third Party Content or Advertising</h2><br />
          <p>TransMe may, from time-to-time provide third party content in the Services and may provide, as a service, links to web pages and content of third parties ("Third Party Content"). TransMe does not monitor or have any control over any Third Party Content or third party websites. Unless otherwise expressly stated by TransMe, TransMe does not endorse or adopt, and is not responsible or liable for, any Third Party Content. TransMe does not make any representations or warranties of any kind regarding the Third Party Content, including, without limitation, any representations or warranties regarding its accuracy, completeness or non infringement. TransMe undertakes no responsibility to update or review any Third Party Content and users use such Third Party Content contained therein at their own risk.</p>
          <h2>Feedback </h2><br />
          <p>You acknowledge and agree that any feedback, comments or suggestions you may provide regarding the Services ("Feedback") will be the sole and exclusive property of TransMe and you hereby irrevocably assign to us all of your right, title and interest in and to all Feedback. You agree that unless otherwise prohibited by law, TransMe may use, sell, disclose and otherwise exploit the Feedback in any way and for any purpose, without restriction and without compensation to you.</p>
          <h2>Player Disputes </h2><br />
          <p>You are solely responsible for your interactions in the Services with other players and any other parties with whom you interact through the Services and/or TransMe services. TransMe reserves the right, but has no obligation, to become involved in any way with these disputes.</p>
          <p>As a condition of using the Services, you release TransMe (and TransMe's shareholders, partners, affiliates, directors, officers, subsidiaries, employees, agents, suppliers, licensees, distributors) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with any dispute you have or claim to have with one or more player of the Services.</p>
          <h2>Choice of Law, Venue and Jurisdiction </h2><br />
          <p>These Terms shall be governed by and construed in accordance with the laws of the State of China, without giving effect to its conflict of law provisions. You agree that any legal action or proceeding between you and TransMe related to these Terms, shall be brought exclusively in a federal or state court of competent jurisdiction sitting in China. You agree to submit to the personal jurisdiction of the courts located within China for the purpose of litigating all such claims or disputes.</p>
          <h2>Binding Arbitration </h2><br />
          <p>Any controversy or claim arising out of or relating to these Terms shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in China, and judgment on the arbitration award may be entered into any court having jurisdiction thereof. Either you or TransMe may seek any interim or preliminary relief from a court of competent jurisdiction in TransMe necessary to protect the rights or property of you or TransMe pending the completion of arbitration.</p>
          <h2>Equitable Relief</h2><br />
          <p>You acknowledge that the rights and obligations under these Terms are of a unique and irreplaceable nature, the loss of which shall cause irreparable harm and which cannot be replaced by monetary damages alone. Accordingly, TransMe shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or any anticipated breach. Furthermore, you hereby irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Services, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Services or any TransMe Content or other material used or displayed through the Services and agree to limit your claims to claims for monetary damages.</p>
          <h2>Indemnification </h2><br />
          <p>You agree to defend, indemnify and hold harmless TransMe, its subsidiaries, affiliates, independent contractors, service providers and consultants, and their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related (a) your use of the Services, (b) any player content or submission you post, store or otherwise transmit on or through the Services, (c) your violation of these Terms and/or any other TransMe terms, policies, or agreements, (d) your violation of any other persons rights, or (e) your conduct in connection with the Services.</p>
          <h2>Digital Millennium Copyright Act ("DMCA") </h2><br />
          <p>Our policy is to respond to notices of alleged infringement that comply with the DMCA. Copyright infringing materials found within the Services can be identified and removed via TransMe's DMCA compliance process posted, and you agree to comply with such process in the event you are involved in any claim of copyright infringement to which the DMCA may be applicable.</p>
          <h2>Disclaimer of Warranties, Limitation of Damages and Release and Indemnity <p></p>
            <p>You agree that the provisions of this section will survive any termination of your account or of the Services.</p>
            <p>THE SERVICES AND ALL RELATED SERVICES ARE PROVIDED BY TransMe ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE, NONINFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. TransMe DOES NOT WARRANT THAT ANY INFORMATION IS COMPLETE OR ACCURATE, THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE OR THAT ANY INFORMATION IS FREE OF VIRUSES AND/OR ROGUE OR MALICIOUS PROGRAMMING.</p>
            <p>UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL TransMe BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT MAY RESULT FROM THE USE OR INABILITY TO USE THE SERVICES, INCLUDING WITHOUT LIMITATION USE OF OR RELIANCE ON INFORMATION AVAILABLE ON THE SERVICES, INTERRUPTIONS, ERRORS, DEFECTS, MISTAKES, OMISSIONS, DELETIONS OF FILES, DELAYS IN OPERATION OR TRANSMISSION, NONDELIVERY OF INFORMATION, DISCLOSURE OF COMMUNICATIONS, OR ANY OTHER FAILURE OF PERFORMANCE.</p>
            <p>IN NO EVENT SHALL THE AGGREGATE LIABILITY OF TransMe, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SERVICES EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO TransMe FOR ACCESS TO OR USE OF THE SERVICES.</p>
            <p>YOU HEREBY RELEASE AND WAIVE ANY AND ALL CLAIMS AND/OR LIABILITY AGAINST TransMe ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SERVICES. YOU ALSO AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS, TransMe, FROM AND AGAINST, ANY AND ALL CLAIMS OR LIABILITY, INCLUDING COSTS AND ATTORNEYS FEES, ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SERVICE OR FAILURE TO ABIDE BY APPLICABLE LAW.</p>
          </h2><h2>Force Majeure</h2><br />
          <p>TransMe shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including without limitation acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
          <h2>Severability </h2><br />
          <p>In the event any provision of these Terms are declared to be void or unenforceable in whole or part, the other provisions of these Terms and the remainder of the affected provisions shall continue to be valid; provided, however, that a court having jurisdiction may revise such provision to the extent necessary to make such provision valid and enforceable consistent with the intention of the parties.</p>
          <h2>Waiver and Conflict </h2><br />
          <p>The failure of TransMe to exercise or enforce any right or provision in these Terms shall not operate as a waiver. As it relates to the Services, if there is ever a conflict between these Terms and any other terms or policies these Terms will control.</p>
          <h2>Children Privacy </h2><br />
          <p>We do not knowingly collect information from children under 18 and we do not want it. We will take steps to delete it if we learn we have collected it. We do not knowingly collect, maintain, or use Personal Information from children under 18 years of age, and no part of the Service is directed to children under the age of 18. If you learn that your child has provided us with Personal Information without your consent, you may alert us at&nbsp;<a href="mailto:mytransdating@gmail.com">mytransdating@gmail.com</a>. If we learn that we have collected any Personal Information from children under 18, we will promptly take steps to delete such information and terminate the child's account.</p>
          <h2>User-Generated Content Policy</h2><br />
          <p>Before using TransMe, you must agree that there is no tolerance for objectionable content with your user-generated content. Any sexually explicit content will be removed without notification. Our moderators review all user-generated content and remove any objectionable content and sexually explicit content within 24 hours. TransMe also removes those who post the objectionable and sexually explicit content without notification. TransMe also reserves the right to take legal means to any constant violations.</p>
          <h2>Modifications to Service.</h2><br />
          <p>TransMe reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that the TransMe shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service. To protect the integrity of the Service, TransMe reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Service.</p>
          <h2>Customer Service </h2><br />
          <p>The Company provides assistance and guidance through its customer care representatives. When communicating with our customer care representatives, you agree to not be abusive, obscene, profane, offensive, sexist, threatening, harassing, racially offensive, or to not otherwise behave inappropriately. If we feel that your behavior towards any of our customer care representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account.</p>
          <h2>Termination </h2><br />
          <p>Notwithstanding any of these Terms, TransMe reserves the right without notice and in its sole discretion to terminate your license to use the Services and block or prevent your future access to, and use of, the Services. You can go to app settings and select 'help', then tap 'delete account'. This will delete your profile completely so you will not appear when other people are playing, we will also delete your matches messages and everything. Upon such termination or suspension, you will not be entitled to any refund of unused fees for in app purchases or on our official site myeasydating.com The Company is not required to disclose, and may be prohibited by law from disclosing, the reason for the termination or suspension of your account. After your account is terminated for any reason, all terms of this Agreement survive such termination, and continue in full force and effect, except for any terms that by their nature expire or are fully satisfied.</p>
          <h2>Special Notice </h2><br />
          <p>Unlike other transgender dating apps, TransMe does not allow any kind of sexual encounters and activities. If we find any violations, we will remove your profiles as soon as possible and take possible legal actions.</p>
          <p>Any lewd, offensive, nude, child alone racist, sexual scene photos are prohibited here. If any violation, your proifle will be banned and we reserve the rights to take further legal actions.
          </p>
          <h2>Questions &amp; Contact Information</h2><br />
          <p>If you have any questions about these Terms and how they relate to the Services please email us at&nbsp;<a href="mailto:mytransdating@gmail.com">mytransdating@gmail.com</a>&nbsp;with the subject line "Terms Question".</p>
          &nbsp;
        </div>
      </div>
    )
  }

  export default Terms;