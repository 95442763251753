import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div id="app">
      <Navbar />
      <div id='content'>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
