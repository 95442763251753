import React from "react";

const Footer = () => {
    return (
        <section id="footer" className="teal lighten-1">
            <div className="container">
                <div className="row">
                    <div className="col s3"></div>
                    <div className="col s6 center-align white-text">
                        © {new Date().getFullYear()} MyEasyDating All Rights Reserved 
                    </div>
                    <div className="col s3"></div>
                </div>
            </div>
        </section>
    )
};

export default Footer;