import React from "react";
import {Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="teal lighten-1">
            <div className="nav-wrapper">
                
                <a href="/" className="brand-logo left">TransMe</a>
                <a href="#" data-activates="mobile-demo" className="button-collapse right">
                    <i className="material-icons">menu</i>
                </a>
                <ul id="mobile-demo" className="right hide-on-med-and-down">
                    <li>
                        {/* <a onClick={nav(1)}>About Us</a> */}
                        <Link to="/about">About Us</Link>
                    </li>
                    <li>
                        <Link to="/pp">Privacy Policy</Link>
                        {/* <a onClick={nav(2)}>Privacy Policy</a> */}
                    </li>
                    <li>
                        <Link to="/tou">Terms Of Use</Link>
                        {/* <a onClick={nav(3)}>Terms Of Use</a> */}
                    </li>
                </ul>
            </div>
        </nav>
    )
};

export default Navbar;