import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './styles/styles.css';
import App from './App';
import AboutUs from './components/AboutUs';
import Policy from './components/Policy';
import Terms from './components/Terms';
import Home from './components/Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// https://reactrouter.com/docs/en/v6/getting-started/tutorial
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<React.StrictMode>
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="" element={<Home />} /> 
        <Route path="about" element={<AboutUs />} />
        <Route path="pp" element={<Policy />} />
        <Route path='tou' element={<Terms />} />
      </Route>
    </Routes>
  </Router>
</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
